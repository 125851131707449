import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import { useEffect } from "react";
import styled from "styled-components";
import { useAnimation, motion } from "framer-motion";
import TypeWriter from "../TypeWriter";
import { sounds } from "../../utils/play";
import Headline from "../layouts/Headline";

export default function AnimatePlayer() {
  const [input, setInput] = useState("Animated Text");
  const [text, setText] = useState("");
  const [effect, setEffect] = useState({
    color: null,
    fontSize: null,
  });
  const [state, setState] = useState({
    pitch: 1.0,
    voices: sounds,
    lang: "en-US",
  });

  function speakButton(text, lang, pitchInput, voices) {
    // Check if the Speech Synthesis API is available in the browser
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      // const speakButton = document.getElementById("speakBtn");
      // Event listener for the Speak button
      let newtext = text.trim();

      if (newtext !== "") {
        const utterance = new SpeechSynthesisUtterance(newtext);

        // Set the voice tone preference using the pitch property
        const pitch = parseFloat(pitchInput);
        if (pitch >= 0.1 && pitch <= 2.0) {
          utterance.pitch = pitch;
        } else {
          alert("Pitch should be between 0.1 and 2.0");
          return;
        }

        // Get the list of available voices
        const voices = synth.getVoices();

        // Select a voice based on your preference
        const selectedVoice = voices.find((voice) => voice.lang === lang);
        if (selectedVoice !== "") {
          utterance.voice = selectedVoice;
        } else {
          alert("No English voice available. Please try a different language.");
          return;
        }

        // Speak the text with the selected voice and tone preference
        synth.speak(utterance);
      } else {
        alert("Please enter some text to speak.");
      }
    } else {
      alert("Speech Synthesis API is not supported in this browser.");
    }
  }

  return (
    <Page
      isActive={true}
      title={"Animate Player"}
      description="Our Animate Player is a powerful and user-friendly tool that
          effortlessly transforms written content into Visual Transformation."
      path="/qr_generator"
    >
      <LeftComponent>
        <Headline text="Write your message" />
        <TextField
          className="!m-3 overflow-auto"
          onChange={(e) => setInput(e.target.value)}
          placeholder="Write your text here..."
          rows={10}
          variant="outlined"
          value={input}
          multiline
        />
        <div className="px-2 flex mobile:flex-col justify-between gap-2">
          <div className="p-1 flex flex-col">
            <TextField
              type="number"
              variant="outlined"
              id="pitch"
              label="Select pitch preference (0.1 to 2.0)"
              size="small"
              InputProps={{
                inputProps: {
                  max: 2.0,
                  min: 0.1,
                  step: 0.1,
                },
              }}
              value={state.pitch}
              onChange={(e) => setState({ ...state, pitch: e.target.value })}
            />
          </div>
          <div className="p-1 flex flex-col">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Voice
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.lang}
                label="Select Voice"
                size="small"
                margin="dense"
                onChange={(e) => setState({ ...state, lang: e.target.value })}
              >
                {sounds.map((voiceEle, i) => (
                  <MenuItem key={i} value={voiceEle.lang}>
                    {voiceEle.name + " " + voiceEle.lang}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </LeftComponent>
      <RightContainer>
        <Headline text="Play your text" />
        <TypeWriter effect={effect} delay={100} text={text} infinite={false} />
        <Stack
          display="flex"
          margin={2}
          flexDirection={"row"}
          justifyContent="center"
          columnGap="1rem"
          alignItems="center"
          textAlign="center"
        >
          <TextField
            className="w-[100px]"
            onChange={(e) => setEffect({ ...effect, color: e.target.value })}
            placeholder="Write your text here..."
            type="color"
            size="small"
            variant="outlined"
            value={effect.color}
          />
          <Button
            variant="contained"
            id="speakBtn"
            onClick={(e) => {
              speakButton(input, state.lang, state.pitch, state.voices);
              // Playit();
              setText(input);
            }}
          >
            Play it
          </Button>
          <TextField
            className="w-[100px]"
            size="small"
            onChange={(e) => setEffect({ ...effect, fontSize: e.target.value })}
            placeholder="12px | 1rem | 24ch"
            variant="outlined"
            value={effect.fontSize}
          />
        </Stack>
      </RightContainer>
      <DescriptionContainer>
        <Typography variant="h4" className="text-xl font-bold">
          Welcome to our Text to Visual Transformation!
        </Typography>
        <p className="py-2">
          Text-to-typing and read transformation involves converting written or
          spoken words into typed text and vice versa. This process often
          utilizes technology such as speech recognition and text-to-speech
          systems. When converting text to typing, the goal is to transcribe
          written content into digital or physical format. Conversely, read
          transformation involves translating typed or digital text into spoken
          words, facilitating accessibility and communication for individuals
          with visual impairments or those in need of auditory information.
        </p>
      </DescriptionContainer>
    </Page>
  );
}
