import { Container } from "@mui/material";
import React from "react";

export default function DescriptionContainer({ children }) {
  return (
    <Container maxWidth="lg">
      <div className="w-full mt-4 p-6">{children}</div>
    </Container>
  );
}
