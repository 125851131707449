import { Button, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import Headline from "../layouts/Headline";

export default function DataToImage() {
  const [state, setState] = useState(null);
  return (
    <Page
      isActive={true}
      title={"Decode Base64 to Image"}
      description="Our Decode Base64 to Image tool allows you to effortlessly transform a Base64 encoded string into a visual image. Base64 encoding is a method used to represent binary data in a textual format, making it easier to handle and transmit over the internet. "
      path="/decode"
    >
      <LeftComponent>
        <Headline text="Paste your decoded Base64 data" />
        <TextField
          className="!m-2 h-[40vh] overflow-auto  p-4"
          onChange={(e) => setState(e.target.value)}
          rows={7}
          placeholder="Write your image data here..."
          variant="outlined"
          value={state}
          multiline
        ></TextField>
      </LeftComponent>
      <RightContainer>
        <Headline text="Preview your image" />
        {state ? (
          <img
            src={state}
            className="!m-2 h-[40vh]"
            alt="No Data"
            height={400}
            width="100%"
          />
        ) : (
          <img
            src=""
            className="!m-2 h-[40vh]"
            alt="No Data"
            height={400}
            width="100%"
          />
        )}
      </RightContainer>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h2 className="fs-10 font-weight-bold how-to__heading-large">
              Decode Base64 to Image tool
            </h2>

            <p className="py-2">
              Our Decode Base64 to Image tool allows you to effortlessly
              transform a Base64 encoded string into a visual image.
            </p>

            <p className="py-2">
              Base64 encoding is a method used to represent binary data in a
              textual format, making it easier to handle and transmit over the
              internet.
            </p>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}
