import React, { useEffect, useState } from "react";
import DescriptionContainer from "../layouts/DescriptionContainer";
import Page from "../Page";

function CanvasPlayer() {
  const [fields, setFields] = useState([
    { font: "", strokeText: "", color: "", align: "center" },
  ]);

  const handleAddField = () => {
    setFields([...fields, { font: "", strokeText: "" }]);
  };

  const handleRemoveField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newFields = fields.map((field, i) =>
      i === index ? { ...field, [name]: value } : field
    );
    setFields(newFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before drawing new text
    let y = 20;
    fields.map((ele, index) => {
      const maxWidth = canvas.width - 20; // Padding
      const lines = wrapText(ctx, ele.strokeText, maxWidth);

      ctx.font = ele.font || "24px Arial";
      ctx.fillStyle = ele.color || "blue";
      ctx.textAlign = ele.align || "left";
      ctx.textBaseline = "top";
      ctx.strokeRect(4, 4, canvas.width - 8, canvas.height - 8);

      const lineHeight = parseInt(ele.font.split("px")[0]) || 30;

      for (const line of lines) {
        ctx.fillText(line, ele.align === "center" ? maxWidth / 2 : 10, y);
        y += lineHeight;
      }
    });
  };

  function downloadCanvas() {
    const canvas = document.getElementById("canvas");
    const link = document.createElement("a");
    link.download = "canvas-design.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  }

  // Helper function to wrap text
  function wrapText(ctx, text, maxWidth) {
    const words = text.split(" ");
    const lines = [];
    let line = "";

    for (const word of words) {
      const testLine = line + word + " ";
      const testWidth = ctx.measureText(testLine).width;

      if (testWidth > maxWidth && line !== "") {
        lines.push(line.trim());
        line = word + " ";
      } else {
        line = testLine;
      }
    }

    lines.push(line.trim());
    return lines;
  }

  return (
    <Page
      title={"Canvas Paint"}
      description="Introducing our cutting-edge Paint Web Application – the ultimate tool for developers! "
      path="/canvas-card"
    >
      <div className="grid gap-2">
        <div className="flex flex-col gap-y-4">
          {fields.map((field, index) => (
            <div className="flex flex-col gap-2" key={index}>
              <div>
                <textarea
                  name="strokeText"
                  className="w-full border p-2 outline-none"
                  value={field.strokeText}
                  onChange={(e) => handleChange(index, e)}
                  placeholder="Hello World!"
                />
              </div>
              <div className="flex justify-between items-end">
                <input
                  name="font"
                  className="w-[120px] border p-2 outline-none"
                  value={field.font}
                  onChange={(e) => handleChange(index, e)}
                  placeholder="50px serif"
                />
                <input
                  name="color"
                  value={field.color}
                  className="w-[60px] h-full border p-2 outline-none"
                  type="color"
                  onChange={(e) => handleChange(index, e)}
                />
                <input
                  name="align"
                  className="w-[60px] border p-2 outline-none"
                  value={field.align}
                  onChange={(e) => handleChange(index, e)}
                />
                <button
                  className="py-2 px-4 text-white font-bold bg-slate-600 border"
                  type="button"
                  onClick={handleAddField}
                >
                  +
                </button>
                <button
                  type="button w-[200px]"
                  className="py-2 px-4 text-white font-bold bg-slate-600 border"
                  onClick={() => handleRemoveField(index)}
                >
                  X
                </button>
              </div>
              <hr />
            </div>
          ))}
          <div id="controls" className="flex justify-between">
            <button
              className="border p-2 bg-[#0f172a] text-white"
              onClick={handleSubmit}
            >
              Draw Text
            </button>
            <button
              className="border p-2 bg-[#0f172a] text-white"
              onClick={downloadCanvas}
            >
              Download Canvas
            </button>
          </div>
        </div>
        <canvas
          className="border"
          id="canvas"
          width="1080px"
          height="1920px"
        ></canvas>
      </div>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h1 className="fs-10 font-weight-bold how-to__heading-large text-2xl font-bold">
              Canvas Player
            </h1>
            <p className="py-2">
              The ultimate digital canvas paint tool designed to ignite your
              creativity and bring your artistic visions to life. Whether you're
              an aspiring artist, a professional illustrator, or simply someone
              who loves to create, our tool provides you with the perfect
              platform to express yourself.
            </p>

            <p className="py-4">
              <strong>Key Features:</strong>
            </p>
            <p className="py-2">
              🎨 <strong>Limitless Possibilities</strong>: Dive into a world of
              endless creative opportunities. Paint, draw, and design with a
              wide range of digital brushes and tools at your fingertips.
            </p>
            <p className="py-2">
              🌟 <strong>Realistic Brushwork</strong>: Experience the natural
              feel of painting with our realistic brush strokes and textures.
              It's as if you're working on a physical canvas.
            </p>
            <p className="py-2">
              🎭 <strong>Layers and Blending</strong>: Create depth and
              dimension in your artwork with layers and blending modes that let
              you achieve professional-grade results.
            </p>
            <p className="py-2">
              🖼️ <strong>Customizable Canvas</strong>: Choose from various
              canvas sizes and dimensions, ensuring that your art fits your
              vision perfectly.
            </p>
            <p className="py-2">
              🎉 <strong>Undo/Redo</strong>: Don't be afraid to experiment. Our
              unlimited undo/redo feature lets you explore without hesitation.
            </p>
            <p className="py-2">
              💾 <strong>Save and Share</strong>: Save your masterpieces and
              share them with the world or keep them for your private
              collection.
            </p>
            <p className="py-4">
              <strong>Why Choose Canvas Paint?</strong>
            </p>
            <p className="py-2">
              🎁 <strong>Innovative Technology</strong>: Our advanced technology
              ensures a smooth and intuitive painting experience that artists of
              all levels will appreciate.
            </p>
            <p className="py-2">
              🌐 <strong>Web-Based Convenience</strong>: Access [Your Tool Name]
              from any device with an internet connection, giving you the
              freedom to create from anywhere.
            </p>
            <p className="py-2">
              🎨 <strong>Inspiration Awaits</strong>: Be inspired by the
              ever-growing community of artists and their diverse creations.
            </p>
            <p className="py-2">
              🔒 <strong>Safe and Secure</strong>: We prioritize your privacy
              and data security, so you can paint with peace of mind.
            </p>
            <p className="py-2">
              Whether you're a digital art enthusiast, an illustrator, or
              someone who simply enjoys the therapeutic nature of painting,
              Canvas Paint offers a welcoming space for your artistic journey.
            </p>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}

export default CanvasPlayer;
