import { Button, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";

export default function HtmlPreview() {
  const [state, setState] = useState(`<!DOCTYPE html>
<html>
<style>
table, th, td {
  border:1px solid black;
}
</style>
<body>

<h2>A basic HTML table</h2>

<table style="width:100%">
  <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
  </tr>
  <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
  </tr>
  <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
  </tr>
</table>

<p className="py-2">To understand the example better, we have added borders to the table.</p>

</body>
</html>`);
  return (
    <Page
      isActive={true}
      title={"HTML PREVIEWER"}
      description="Our HTML preview tool can be very helpful when you want to quickly check how your HTML code will appear in a web browser without the need to set up a full web development environment. It's particularly useful for testing and debugging purposes before implementing the code on a live website or web application."
      path="/html_preview"
    >
      <LeftComponent>
        <Paper elevation={0} className="flex mobile:flex-col laptop:flex-row">
          <Typography className="flex-1 !m-3" variant="h5">
            Write your code here...
          </Typography>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setState(`<!DOCTYPE html>
<html>
<style>
table, th, td {
  border:1px solid black;
}
</style>
<body>

<h2>A basic HTML table</h2>

<table style="width:100%">
  <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
  </tr>
  <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
  </tr>
  <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
  </tr>
</table>

<p className="py-2">To understand the example better, we have added borders to the table.</p>

</body>
</html>

`);
            }}
            variant="contained"
            className="!m-3"
          >
            RESET
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setState(``);
              console.log(state);
            }}
            variant="contained"
            className="!m-3"
          >
            CLEAR
          </Button>
        </Paper>

        <TextField
          className="!m-2 h-[80vh] overflow-auto bg-slate-900 !text-white"
          onChange={(e) => setState(e.target.value)}
          inputProps={{ style: { color: "white" } }}
          variant="outlined"
          // rows={6}
          placeholder="Write your html code here..."
          value={state}
          multiline
        ></TextField>
      </LeftComponent>
      <RightContainer>
        <Paper elevation={0} className="flex">
          <Button
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("preview").innerHTML = state;
            }}
            variant="contained"
            className="!m-3 w-full"
          >
            Preview
          </Button>
        </Paper>

        <div className="m-2 h-[80vh]" id="preview"></div>
      </RightContainer>
      {/* </div> */}

      <DescriptionContainer>
        <h2 className="font-bold text-center text-3xl">How to Use</h2>
        <h3 className="text-xl py-2">Find an HTML Preview Tool:</h3>
        <p className="py-2">
          Search for an online HTML preview tool using your preferred search
          engine. There are various options available, and you can choose one
          that suits your requirements.
        </p>

        <h3 className="text-xl py-2">Open the HTML Preview Tool:</h3>
        <p className="py-2">
          Once you've found a suitable HTML preview tool, open it in your web
          browser. Most online HTML preview tools are web-based, so there is no
          need to download or install any software.
        </p>

        <h3 className="text-xl py-2">Access the HTML Input:</h3>
        <p className="py-2">
          In the HTML preview tool, there will be an input area where you can
          paste or enter your HTML code. This is the area where you will write
          or copy your HTML code to preview it.
        </p>

        <h3 className="text-xl py-2">Enter or Paste HTML Code:</h3>
        <p className="py-2">
          Write your HTML code directly in the input area or paste it from your
          clipboard. This code should include all the HTML tags, elements, and
          content you want to preview.
        </p>

        <h3 className="text-xl py-2">Trigger the Preview:</h3>
        <p className="py-2">
          Some HTML preview tools automatically update the preview as you type
          or paste the code. If not, there may be a "Preview" button or an
          option to generate the preview. Click on it to see the rendered output
          of your HTML code.
        </p>

        <h3 className="text-xl py-2">Observe the Preview:</h3>
        <p className="py-2">
          After clicking the preview button, the tool will render your HTML code
          and display the output in a separate window or pane. This is where you
          can see how your HTML content looks when rendered by a web browser.
        </p>

        <h3 className="text-xl py-2">Revise and Repeat (if necessary):</h3>
        <p className="py-2">
          If you notice any issues or errors in the preview, you can go back to
          the input area, make necessary changes to your HTML code, and trigger
          the preview again to see the updated result.
        </p>

        <h3 className="text-xl py-2">
          Save or Share the Result (if available):
        </h3>
        <p className="py-2">
          Some HTML preview tools provide options to save or share the rendered
          HTML output. If you want to keep a copy or share the preview with
          others, look for such options within the tool.
          <br />
          Using an HTML preview tool can be very helpful when you want to
          quickly check how your HTML code will appear in a web browser without
          the need to set up a full web development environment. It's
          particularly useful for testing and debugging purposes before
          implementing the code on a live website or web application.
        </p>
      </DescriptionContainer>
    </Page>
  );
}
