import { TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import { format, intervalToDuration } from "date-fns/esm";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import Headline from "../layouts/Headline";

export default function Color() {
  const [color, setColor] = useColor("#561ecb");

  return (
    <Page
      isActive={true}
      title={"Color Picker"}
      description="A color picker tool is a software or web application feature that allows users to select and identify specific colors from an image, on-screen display, or a predefined color palette. "
      path="/color"
    >
      <LeftComponent>
        <Headline text="Choose colors" />
        <div className="!m-2 p-4">
          <ColorPicker color={color} onChange={setColor} />
        </div>
      </LeftComponent>
      <RightContainer>
        <Headline text="Output color code" />
        <div className="!m-2 w-full p-4">
          <TextField
            className="w-full"
            disabled
            type="color"
            variant="outlined"
            value={color.hex}
          />
        </div>
      </RightContainer>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h2 className="fs-10 font-weight-bold how-to__heading-large">
              Color Picker
            </h2>
            <p className="py-2">
              A color picker tool is a software or web application feature that
              allows users to select and identify specific colors from an image,
              on-screen display, or a predefined color palette. This tool is
              commonly used in graphic design, web development, digital art, and
              other creative fields where precise color selection is crucial.
              Here's a description of how a typical color picker tool works and
              its key features:
            </p>
            <ol>
              <li className="py-2">
                <p className="py-2">
                  <strong>Color Sampling</strong>: The primary function of a
                  color picker tool is to sample or pick colors from a source.
                  This source can be an image, a portion of the screen, or a
                  predefined color swatch.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Eyedropper Tool</strong>: The most common method for
                  picking colors is through an "eyedropper" tool. When
                  activated, this tool turns the cursor into a small pipette
                  icon that can be used to click on any pixel on the screen or
                  within an image. The tool then identifies the color of the
                  selected pixel and displays its RGB (Red, Green, Blue), HEX
                  (Hexadecimal), HSL (Hue, Saturation, Lightness), or other
                  color values.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Color Palette</strong>: A color picker often includes
                  a palette or swatch panel displaying a range of predefined
                  colors. Users can choose colors from this palette if they
                  don't want to sample colors directly.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Color Preview</strong>: As users select or hover over
                  different colors, a preview area typically shows the selected
                  color in a larger format. This allows users to see the color
                  before making a final selection.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Color Values</strong>: The tool usually provides
                  numeric values for the selected color's components (RGB, HEX,
                  HSL, etc.). These values are essential for replicating the
                  chosen color in design or development work.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Color History</strong>: Some color pickers maintain a
                  history of recently selected colors, making it easy to switch
                  between previously used colors.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Color Manipulation</strong>: Advanced color pickers
                  may offer features for adjusting the selected color, such as
                  brightness, saturation, contrast, and opacity.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Integration</strong>: Color picker tools are often
                  integrated into graphic design software, web development
                  environments, and image editing programs, allowing users to
                  apply selected colors directly to their projects.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Cross-Platform</strong>: Many color picker tools are
                  available both as standalone applications and as browser-based
                  tools, making them accessible on various platforms.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Keyboard Shortcuts</strong>: Some color pickers offer
                  keyboard shortcuts to enhance the efficiency of color
                  selection.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Export Options</strong>: Users can usually export the
                  selected color values for easy integration into their
                  projects, including CSS for web design or color codes for
                  graphic design software.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}
