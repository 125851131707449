import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <Grid item xs={12}>
      <Box
        component="footer"
        className="!bg-slate-900 text-white"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="body1">
            Ioberg presents innovative tools.
          </Typography>

          <Typography variant="body2" className="text-white">
            {"Copyright © "}
            Ioberg
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
        <div className="my-8 flex flex-col laptop:flex-row justify-between m-auto gap-y-4 max-w-[96%] laptop:max-w-[80%]">
          <div className="leading-8 text-xl">
            <h4 className="font-bold">About Us</h4>
            <p>Ioberg & MyIoberg Pvt Ltd</p>
            <p>Delhi,India</p>
          </div>
          <div className="leading-8 text-xl">
            <h4 className="font-bold">Useful Links</h4>
            <p>
              <a href="https://my.ioberg.com">MyIoberg</a>
            </p>
          </div>
          <div className="leading-8 text-xl">
            <h4 className="font-bold">Contact Us</h4>
            <p>Email: stirthraj.12345@gmail.com </p>
            <p>Phone: +91 7978821389</p>
          </div>
        </div>
      </Box>
    </Grid>
  );
}

export default Footer;
