import React, { useEffect, useState } from "react";
import Pen from "../assets/pen.png";
import { TextField } from "@mui/material";

export default function TypeWriter({ effect, text, delay, infinite }) {
  const [currText, setCurrText] = useState("");
  const [currIndex, setCurrIndex] = useState(0);

  useEffect(() => {
    let time;
    if (currIndex < text.length) {
      time = setTimeout(() => {
        setCurrText((ptext) => ptext + text[currIndex]);
        setCurrIndex((prev) => prev + 1);
      }, delay);
    } else if (infinite) {
      setCurrText("");
      setCurrIndex(0);
    }

    return () => clearTimeout(time);
  }, [currIndex, delay, infinite, text]);
  useEffect(() => {
    setCurrText("");
    setCurrIndex(0);
  }, [text]);
  return (
    <TextField
      className="!m-3 overflow-auto dark:bg-black bg-white rounded-lg"
      //   onChange={(e) => setState(e.target.value)}
      placeholder="Write your text here..."
      rows={10}
      // label="Outlined secondary"
      inputProps={{
        style: {
          color: effect.color || "black",
          fontSize: effect.fontSize || "1rem",
        },
      }}
      variant="outlined"
      value={currText}
      multiline
    />
  );
}
