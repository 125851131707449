import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import Link from "@mui/material/Link";

export default function ToolCard({ cards, xs, sm, md, lg }) {
  return (
    <>
      {cards.slice(0, 16).map((card, i) => (
        <Grid item key={card.id} xs={xs} sm={sm} md={md} lg={lg}>
          <Link reloadDocument to={card.link}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              className="!rounded-xl border"
            >
              <div
                className="bg-slate-800"
                style={{
                  height: "160px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                  // backgroundImage: "radial-gradient(#12486B, #419197)",
                  // border: "1px solid white",
                }}
              >
                <div className="text-2xl px-2 font-bold text-white">
                  {" "}
                  {card.name}
                </div>
              </div>

              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  className="text-[#204a65] font-bold"
                  gutterBottom
                  variant="h5"
                  component="h3"
                >
                  {card.name}
                </Typography>
                <Typography
                  title={card.description}
                  className="text-[#3c8fc5] line-clamp-3"
                >
                  {card.description}
                </Typography>
              </CardContent>
              <CardActions>
                {/* <Button size="small">View</Button>
                    <Button size="small">Edit</Button> */}
              </CardActions>
            </Card>
          </Link>
        </Grid>
      ))}
    </>
  );
}
