import { TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import { format, intervalToDuration } from "date-fns/esm";
import Headline from "../layouts/Headline";

export default function Age() {
  const [state, setState] = useState({
    start: format(new Date(), "yyyy-MM-dd"),
    end: format(new Date(), "yyyy-MM-dd"),
  });

  function rightObject() {
    let differenceOfDate = intervalToDuration({
      start: new Date(state.start.toString()),
      end: new Date(state.end.toString()),
    });
    let list = [];

    for (const [key, value] of Object.entries(differenceOfDate)) {
      list.push(
        <Typography className="p-2 border-2 border-solid" variant="subtitle">
          {key + "-" + value}
        </Typography>
      );
    }
    return list;
  }

  return (
    <Page
      isActive={true}
      title={"Age Calculator|Time Difference"}
      description="Our Age Calculator help you to find the correct age of you just by mentioning your Date of Birth"
      path="/age"
    >
      <LeftComponent>
        <Headline text="Choose Dates" />
        <TextField
          className="!m-2 p-4"
          onChange={async (e) => {
            setState({ ...state, start: e.target.value });
          }}
          // rows={6}
          variant="outlined"
          value={state.start}
          type="date"
          placeholder="Date of Birth"
        ></TextField>
        <TextField
          className="!m-2 p-4"
          variant="outlined"
          onChange={async (e) => {
            setState({ ...state, end: e.target.value });
            console.log("Hello", e.target.value);
          }}
          // rows={6}
          value={state.end}
          type="date"
          placeholder="Date of Birth"
        ></TextField>
      </LeftComponent>
      <RightContainer>
        <Headline text="Time difference calculate" />
        {rightObject()}
      </RightContainer>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h2 className="fs-10 font-weight-bold how-to__heading-large">
              Age Calculator or Time Interval Tool
            </h2>
            <p className="py-2">
              An age calculator tool is a simple software or web-based
              application designed to calculate a person's age based on their
              birthdate and the current date. This tool is commonly used for a
              variety of purposes, including verifying age eligibility for
              certain activities or services, such as purchasing alcohol or
              entering a website with age restrictions. Here's a short
              description of how an age calculator tool typically works:
            </p>
            <ol>
              <li className="py-2">
                <p className="py-2">
                  <strong>Input Fields</strong>: Users are presented with input
                  fields where they can enter their birthdate. The input fields
                  may include options to specify the day, month, and year of
                  birth.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Current Date</strong>: The tool automatically captures
                  the current date or allows users to enter it manually if
                  needed.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Calculate Button</strong>: After entering the
                  birthdate and current date, users click a "Calculate" button
                  or a similar action to initiate the age calculation.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Age Display</strong>: The tool then processes the
                  input and displays the person's calculated age in years,
                  months, and/or days. It may also provide additional
                  information, such as the exact age in years or a rounded age.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Optional Features</strong>: Some age calculators may
                  offer additional features, such as calculating age in
                  different units (e.g., weeks or hours) or providing
                  information about upcoming birthdays.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Error Handling</strong>: Good age calculator tools
                  include error handling to ensure that users enter valid dates
                  and that the calculation is accurate.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}
