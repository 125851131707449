import React, { useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import Headline from "../layouts/Headline";
import { Button, TextField } from "@mui/material";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Speech = () => {
  const [state, setState] = useState();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Page
      isActive={true}
      title="Spoken Words to Text"
      description="your go-to destination for transforming spoken language into written text effortlessly and accurately. Our cutting-edge speech recognition technology empowers individuals and businesses to transcribe, caption, and document spoken content with unmatched precision."
      path="/speech"
    >
      <LeftComponent>
        <Headline text="Speak here" />
        <div className="!m-2 p-4">
          <Button
            variant="contained"
            className="!m-2"
            onClick={SpeechRecognition.startListening}
          >
            Start
          </Button>
          <Button
            variant="contained"
            className="!m-2"
            onClick={SpeechRecognition.stopListening}
          >
            Stop
          </Button>
          <Button
            variant="contained"
            className="!m-2"
            onClick={resetTranscript}
          >
            Reset
          </Button>
        </div>
      </LeftComponent>
      <RightContainer>
        <Headline text="Converted text from audio" />
        <div className="!m-2 p-4">
          <div>
            <p>Microphone: {listening ? "on" : "off"}</p>
          </div>
          <TextField
            id="filled-basic"
            variant="outlined"
            label=""
            className="w-full"
            placeholder="Write your syntex and paragraph..."
            multiline
            value={transcript}
          />
          <Button
            variant="contained"
            className="!my-2"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard
                .writeText(transcript)
                .then(() => {
                  console.log("Text copied to clipboard");
                })
                .catch((err) => {
                  console.error("Failed to copy text: ", err);
                });
            }}
          >
            <ContentCopyIcon fontSize="medium" />
          </Button>
        </div>
      </RightContainer>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h2 className="fs-10 font-weight-bold how-to__heading-large">
              Convert Spoken Words to Text with Precision
            </h2>
            <h2 className="py-2">Key Features:</h2>
            <p className="py-2">
              🎙️ Real-Time Transcription: Speak into your microphone, and watch
              as your words instantly transform into text before your eyes.
              Perfect for meetings, interviews, or note-taking on the fly.
            </p>
            <p className="py-2">
              🔍 High Accuracy: Our advanced algorithms ensure the highest
              levels of accuracy, capturing the nuances and subtleties of spoken
              language.
            </p>
            <p className="py-2">
              🌐 Web-Based Convenience: Access our tool directly from your web
              browser—no downloads or installations required.
            </p>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
};
export default Speech;
