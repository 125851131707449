import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import Headline from "../layouts/Headline";
import uploadImg from "../../assets/upload_image.png";

export default function ImageUploader() {
  const [state, setState] = useState();
  const [images, setImages] = useState([]);

  let getImages = useCallback(() => {
    async function fetchApi() {
      let resp = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "v1/imgupload/images"
      );
      setImages(resp.data.result);
    }
    fetchApi();
  }, []);

  useEffect(() => {
    getImages();
  }, []);

  return (
    <Page
      isActive={true}
      title={"Image Upload"}
      description="Our Image Upload is a powerful and user-friendly tool that effortlessly transforms file to url. Use where you wants to immediately."
      path="/image_uploader"
    >
      <LeftComponent>
        <Headline text="Upload your image" />
        <div className="h-full w-full grid place-items-center border-2 border-grey-600">
          <div className="relative inline-block w-[200px] h-[48px]">
            <label className="absolute p-2 top-0 left-0 bg-[#c4c4c424] cursor-pointer flex flex-col justify-center items-center">
              <img src={uploadImg} className="w-[28px] h-[28px]" alt="upload" />
              <p className="text-[9px] font-bold italic text-center text-[#00587B]">
                Click here to upload image(s)
              </p>
            </label>
            <input
              type="file"
              className="opacity-[0] absolute top-0 left-0 h-full w-full"
              onDragOver={(e) => e.preventDefault()}
              onDrop={async (event) => {
                //prevent the browser from opening the image
                event.preventDefault();
                event.stopPropagation();
                //let's grab the image file
                let imageFile = event.dataTransfer.files[0];
                let form = new FormData();
                form.append("file", imageFile);
                let maxSize = 512000;

                if (imageFile.size < maxSize) {
                  const result = await axios.post(
                    process.env.REACT_APP_BACKEND_URL + "v1/imgupload/save",
                    form
                  );
                  let { data } = result;
                  setState(data?.s3Url?.Location);
                  // video.set("title", data?.s3Url?.key);
                  // video.set("subtitle", data?.s3Url?.key);
                  // video.set("sources_hd", data?.s3Url?.Location);
                  // video.set("sources_mp4", data?.s3Url?.Location);

                  console.log("Url", data);
                } else {
                  alert("Max Image Size 200kb");
                }
              }}
              onChange={async (e) => {
                try {
                  let form = new FormData();
                  form.append("file", e.target.files[0]);

                  let maxSize = 512000;

                  if (e.target.files[0].size < maxSize) {
                    const result = await axios.post(
                      process.env.REACT_APP_BACKEND_URL + "v1/imgupload/save",
                      form
                    );
                    let { data } = result;
                    setState(data?.s3Url?.Location);
                    getImages();
                    // video.set("title", data?.s3Url?.key);
                    // video.set("subtitle", data?.s3Url?.key);
                    // video.set("sources_hd", data?.s3Url?.Location);
                    // video.set("sources_mp4", data?.s3Url?.Location);

                    console.log("Url", data);
                  } else {
                    alert("Max Image Size 5MB");
                  }
                } catch (error) {
                  console.error("Cannot upload image.");
                  console.error(error);
                }
              }}
            />
          </div>
        </div>
      </LeftComponent>
      <RightContainer>
        <Headline text="Get link quickly" />
        <div className="!m-3">
          <img src={state} height="70%" width="100%" alt="" />

          <Stack
            display="flex"
            margin={2}
            alignItems="center"
            textAlign="center"
          >
            <Link
              m={2}
              href="/"
              id="download"
              //   download="tts.mp3"
              variant="contained"
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard
                    .writeText(state)
                    .then(() => {
                      console.log("Text copied to clipboard");
                    })
                    .catch((err) => {
                      console.error("Failed to copy text: ", err);
                    });
                }}
                variant="contained"
              >
                COPY URL
              </Button>
            </Link>
          </Stack>
        </div>
      </RightContainer>

      <DescriptionContainer>
        <Grid container spacing={2}>
          {images.map((ele, i) => (
            <Grid key={i} item xs={6} md={2} sm={6}>
              <img
                className="object-contain border-2 border-grey-200 h-full w-full"
                src={ele.Location}
                alt={ele.key}
                height="200px"
                width="200px"
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="h2" className="text-2xl font-bold">
          Welcome to our Image Upload!
        </Typography>
        <p className="py-2">
          Creating an Image Upload with a maximum file size of 200KB involves
          building a web-based application that allows users to upload images
          and generates a URL for sharing the uploaded image.
        </p>
        <h3>Key Features:</h3>
        <ol style={{ listStyle: "auto" }}>
          <li className="py-2">
            Easy-to-Use Interface: Our converter boasts an intuitive and
            user-friendly interface, making it accessible to users of all levels
            of tech-savviness. You can uload your image to get url with just a
            few clicks, no complicated setups or installations required.
          </li>
        </ol>
      </DescriptionContainer>
    </Page>
  );
}
