import * as React from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
// import HandymanIcon from "@mui/icons-material/Handyman";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToolCard from "./ToolCard";
import cards from "../components/data/cards.json";
import services from "../components/data/services.json";
import team from "../components/data/team.json";
import testing from "../components/data/testing.json";
import Headline from "./layouts/Headline";
import Particle from "./Particle";
import { Button, Stack } from "@mui/material";
import Footer from "./layouts/Footer";

function Copyright() {
  return (
    <Typography variant="subtitle1" align="center">
      {"Copyright © "}
      <Link href="https://ioberg.com">Ioberg</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Tools() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Particle />
      <AppBar color="inherit" elevation={0} position="relative">
        <Toolbar className="!bg-slate-900 justify-between flex-col laptop:flex-row gap-y-4 py-2">
          <a href="/">
            <img
              src="/logo.png"
              alt=" IOBERG TOOLS"
              height={40}
              width="auto"
              className="h-[40px]"
            />
          </a>
          <div className="grid grid-cols-3 space-x-1">
            <a className="" href="https://blog.ioberg.com/">
              <Button
                variant="outlined"
                className="w-full font-bold !text-white"
              >
                Blog
              </Button>
            </a>
            <a className="" href="https://my.ioberg.com/">
              <Button
                variant="outlined"
                className="w-full font-bold !text-white"
              >
                MyIoberg
              </Button>
            </a>
            <a className="" href="https://code.ioberg.com/">
              <Button
                variant="outlined"
                className="w-full font-bold !text-white"
              >
                Code
              </Button>
            </a>
          </div>
        </Toolbar>
      </AppBar>
      <main
        className="bg-white"
        style={{
          // background: "#12486B",
          color: "black",
        }}
      >
        {/* Hero unit */}
        <Box
          className="text-white bg-slate-950 mb-4"
          sx={{
            pt: 8,
          }}
        >
          <Container
            maxWidth="lg"
            className="!flex !flex-col !p-0 laptop:!flex-row"
          >
            <Container className="flex-1" maxWidth="md">
              <Typography
                component="h1"
                className="!text-4xl laptop:!text-6xl"
                variant="h1"
                align="center"
                // color="text.primary"
                gutterBottom
              >
                MyIoberg Handle
              </Typography>
              <Typography
                variant="h5"
                align="center"
                // color="text.secondary"
                paragraph
              >
                Your All-in-One Link Management and Analytics Solution.
                Transform your online presence into a dynamic and data-driven
                powerhouse with My Ioberg Handle. Whether you're an influencer,
                marketer, or business owner, our platform empowers you to take
                full control of your digital footprint.
              </Typography>
              <Stack
                sx={{
                  pt: 4,
                  backgroundColor: "white",
                }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                <a href="https://my.ioberg.com">
                  <Button
                    className="!border-[50%] !text-2xl !capitalize !px-12 !py-1 !bg-slate-900 hover:!bg-slate-700"
                    variant="contained"
                  >
                    MyIoberg
                  </Button>
                </a>
              </Stack>
            </Container>
          </Container>
        </Box>
        <Container className="flex" maxWidth="md">
          <Typography
            variant="h6"
            align="center"
            // color="text.secondary"
            paragraph
          >
            Your ultimate destination for high-quality and innovative tools.
            From precision engineering to durable construction, our wide range
            of products ensures superior performance in every job. Experience
            efficiency and excellence with IOBERG TOOLS – empowering you to
            achieve more in your projects.
          </Typography>
        </Container>

        <Container
          sx={{
            py: 8,
            color: "white",
          }}
          maxWidth="lg"
        >
          {/* <Headline text="Our Products" /> */}
          {/* Every tool page have these tools */}
          <Grid container spacing={4}>
            <ToolCard cards={cards} xs={12} sm={6} md={4} />
          </Grid>
          <Headline text="Our Services" />
          {/* Every tool page have these tools */}
          <Grid container spacing={4}>
            <ToolCard cards={services} xs={12} sm={6} md={4} lg={4} />
          </Grid>
          <Headline text="Our Testing" />
          {/* Every tool page have these tools */}
          <Grid container spacing={4}>
            <ToolCard cards={testing} xs={12} sm={6} md={4} lg={4} />
          </Grid>
          <Headline text="Our Team" />
          {/* Every tool page have these tools */}
          <Grid container spacing={4}>
            <ToolCard cards={team} xs={12} sm={6} md={4} lg={4} />
          </Grid>

          <Grid container spacing={4} className="px-8">
            <div className="relative flex w-full flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
              <div className="flex flex-grow flex-col gap-3 max-w-full">
                <div className="min-h-[20px] flex flex-col items-start gap-3 overflow-x-auto whitespace-pre-wrap break-words">
                  <div className="my-8">
                    {cards.map((element, index) => (
                      <div key={index} className="my-4">
                        <h3 className="pt-6 text-[#12486B] text-xl font-bold">
                          {element.name}
                        </h3>
                        <p className="text-[#12486B] py-2">
                          {element.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </main>
      <Footer />
    </ThemeProvider>
  );
}
