import { Button, Typography } from "@mui/material";
import React from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import Headline from "../layouts/Headline";

export default function WebCam() {
  let mediaRecorder;
  let recordedChunks = [];

  // Function to start video recording
  async function startRecording() {
    try {
      const videoElement = document.getElementById("videoElement");
      const downloadLink = document.getElementById("downloadLink");
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      videoElement.srcObject = stream;
      // videoElement.current = stream;
      mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const recordedBlob = new Blob(recordedChunks, {
          type: "video/webm",
        });
        recordedChunks = [];
        const videoURL = URL.createObjectURL(recordedBlob);
        document.getElementById("message").style.display = "none";
        downloadLink.href = videoURL;
        downloadLink.download = "recorded_video.webm";
        downloadLink.style.display = "block";
      };

      mediaRecorder.start();
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  }

  // Function to stop video recording
  function stopRecording() {
    const videoElement = document.getElementById("videoElement");
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      videoElement.srcObject.getTracks().forEach((track) => track.stop());
    }
  }

  // Event listeners for the buttons
  // startButton.addEventListener("click", startRecording);
  // stopButton.addEventListener("click", stopRecording);

  return (
    <Page
      isActive={true}
      title={"Web Cam Test"}
      description="Our QR Generator is a powerful and user-friendly tool that
          effortlessly transforms written content into QR."
      path="/webcam"
    >
      <LeftComponent>
        <Headline text="Test your camera and record" />
        <div className="flex justify-between">
          <Button
            variant="contained"
            id="startBtn"
            className="!my-2"
            onClick={(e) => startRecording(e)}
          >
            Start Recording
          </Button>
          <Button
            variant="contained"
            className="!my-2"
            id="stopBtn"
            onClick={(e) => stopRecording(e)}
          >
            Stop Recording
          </Button>
        </div>
        <video
          className="border-2 border-green-950"
          id="videoElement"
          width="640"
          height="360"
          autoPlay
        ></video>
      </LeftComponent>
      <RightContainer>
        <Headline text="Download recorded video by Webcam" />
        <Button id="message">No Recording</Button>
        <a
          className="text-center"
          id="downloadLink"
          href="/"
          style={{ display: "none" }}
        >
          <Button variant="contained">Download Recorded Video</Button>
        </a>
      </RightContainer>

      <DescriptionContainer>
        <Typography variant="h2" className="text-2xl font-bold">
          Web Cam Test | Capture | Download
        </Typography>
        <p className="py-2">
          Our tool you're referring to seems to involve webcam functionality,
          allowing users to test, capture, and download webcam footage. While
          you haven't provided detailed specifications, here's a general
          description of how such a tool might work:
        </p>
        <h3>Key Features:</h3>
        <ol style={{ listStyle: "auto" }}>
          <li className="py-2">
            Testing Webcam: The tool provides a feature to check if the user's
            webcam is working properly. This could involve displaying a live
            feed from the webcam on the screen. Users can ensure that their
            webcam is correctly connected and functional before proceeding to
            the next steps.
          </li>
          <li className="py-2">
            Capturing Webcam Footage: Once the user's webcam is verified to be
            working, the tool allows users to capture footage from the webcam.
            Users might have the option to configure settings such as video
            resolution, frame rate, and audio recording. The captured footage
            could be used for various purposes like creating videos, video
            messages, or conducting video interviews.
          </li>
          <li className="py-2">
            {" "}
            Downloading Captured Footage: After capturing the webcam footage,
            the tool provides a way for users to download the captured content.
            Users might have the option to choose the format (e.g., MP4, AVI)
            and quality of the downloaded video. Additionally, the tool could
            offer basic video editing features like trimming, adding text
            overlays, or applying filters before downloading the final video.
          </li>
          <li className="py-2">
            Privacy and Security: The tool should prioritize user privacy and
            security. Ensure that the webcam access is used only for the
            intended purposes and that user data is not stored or shared without
            explicit consent.
          </li>
          <li className="py-2">
            User-Friendly Interface: The tool should have an intuitive and
            user-friendly interface, making it easy for users of all levels of
            technical expertise to navigate through the testing, capturing, and
            downloading processes.
          </li>
          <li className="py-2">
            Compatibility: The tool should be compatible with a range of
            operating systems and web browsers to cater to a broader user base.
          </li>
          <li className="py-2">
            Tips and Troubleshooting: Include helpful tips and troubleshooting
            guides to assist users in case they encounter issues with their
            webcam, capturing process, or downloading the footage.
          </li>
          <li className="py-2">
            Additional Features (Optional): Depending on the tool's intended
            use, you might consider adding features such as:
            <ul>
              <li className="py-2">Webcam effects and filters.</li>
              <li className="py-2">Integration</li>
            </ul>
          </li>
        </ol>
      </DescriptionContainer>
    </Page>
  );
}
