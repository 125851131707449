import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import { sounds } from "../../utils/play";
import Headline from "../layouts/Headline";

export default function TextToAudioPlay() {
  const [state, setState] = useState({
    pitch: 1.0,
    voices: sounds,
    lang: "en-US",
  });

  function speakButton() {
    // Check if the Speech Synthesis API is available in the browser
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      const textArea = document.getElementById("text");
      const pitchInput = document.getElementById("pitch");
      // const speakButton = document.getElementById("speakBtn");
      // Event listener for the Speak button
      const text = textArea.value.trim();

      if (text !== "") {
        const utterance = new SpeechSynthesisUtterance(text);

        // Set the voice tone preference using the pitch property
        const pitch = parseFloat(pitchInput.value);
        if (pitch >= 0.1 && pitch <= 2.0) {
          utterance.pitch = pitch;
        } else {
          alert("Pitch should be between 0.1 and 2.0");
          return;
        }

        // Get the list of available voices
        const voices = synth.getVoices();

        // Select a voice based on your preference
        const selectedVoice = voices.find((voice) => voice.lang === state.lang);
        if (selectedVoice !== "") {
          utterance.voice = selectedVoice;
        } else {
          alert("No English voice available. Please try a different language.");
          return;
        }

        // Speak the text with the selected voice and tone preference
        synth.speak(utterance);
      } else {
        alert("Please enter some text to speak.");
      }
    } else {
      alert("Speech Synthesis API is not supported in this browser.");
    }
  }

  useEffect(() => {
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      setState({ ...state, voices: synth.getVoices() });
    }
  }, []);

  return (
    <Page
      isActive={true}
      title={"Text to Audio Play"}
      description="Our Text to Audio Play is a powerful and user-friendly tool that
          effortlessly transforms written content into high-quality audio files."
      path="/ttsplay"
    >
      <LeftComponent>
        <div className="p-4 flex flex-col gap-y-2">
          <Headline text="Write your message" />
          <TextField
            multiline
            variant="outlined"
            id="text"
            placeholder="Write you text here..."
            rows="4"
          />

          <TextField
            type="number"
            variant="outlined"
            id="pitch"
            label="Select pitch preference (0.1 to 2.0)"
            size="small"
            InputProps={{
              inputProps: {
                max: 2.0,
                min: 0.1,
                step: 0.1,
              },
            }}
            value={state.pitch}
            onChange={(e) => setState({ ...state, pitch: e.target.value })}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Voice</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state.lang}
              label="Select Voice"
              size="small"
              onChange={(e) => setState({ ...state, lang: e.target.value })}
            >
              {state.voices.map((voiceEle, i) => (
                <MenuItem key={i} value={voiceEle.lang}>
                  {voiceEle.name + " " + voiceEle.lang}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </LeftComponent>
      <RightContainer>
        <Button
          variant="contained"
          className="!m-4"
          id="speakBtn"
          onClick={(e) => speakButton(e)}
        >
          Speak
        </Button>
      </RightContainer>

      <DescriptionContainer>
        <Typography variant="h2" className="font-bold text-2xl">
          Welcome to our Text to Audio Play!
        </Typography>
        <p className="py-2">
          <strong>1. Text Input:</strong>
          The tool provides a text input area where users can type or paste the
          text they want to convert into audio. This could be any type of text,
          such as articles, documents, messages, or even web content.
        </p>
        <p className="py-2">
          <strong>2. Language Selection:</strong>
          Users can choose the language in which they want the text to be
          converted into audio. Google's speech synthesis supports a wide range
          of languages and accents, so users should have the flexibility to
          select their preferred language and voice.
        </p>
        <p className="py-2">
          <strong>3. Voice Options:</strong>
          Google's speech synthesis technology often provides various voice
          options with distinct tones and accents. The tool should offer a
          selection of available voices for users to choose from.
        </p>
        <p className="py-2">
          <strong>4. Audio Preview:</strong>
          Before generating the final audio, the tool could provide users with a
          preview of how the selected text would sound with the chosen voice and
          language. This allows users to make adjustments if needed.
        </p>
        <p className="py-2">
          <strong>5. Generate Audio:</strong>
          Once satisfied with the settings, users can initiate the text-to-audio
          conversion process. The tool uses Google's speech synthesis API to
          transform the text into a natural-sounding audio clip.
        </p>
        <p className="py-2">
          <strong>6. Playback Controls:</strong>
          The generated audio should be accompanied by playback controls, such
          as play, pause, rewind, and volume adjustment. Users can listen to the
          audio to ensure it meets their expectations.
        </p>
        <p className="py-2">
          <strong>7. Download Option:</strong>
          After generating the audio, users should have the option to download
          the audio clip in a preferred audio format, such as MP3 or WAV. This
          allows users to save the audio file for offline use or sharing.
        </p>
        <p className="py-2">
          <strong>8. Sharing and Embedding:</strong>
          To
        </p>
      </DescriptionContainer>
    </Page>
  );
}
