import { Grid, Typography } from "@mui/material";
import React from "react";

export default function ToolTitleDescription({ title, description, isActive }) {
  if (!isActive) return null;
  return (
    <Grid item xs={12}>
      <Typography
        className="text-center mobile:!text-2xl laptop:!text-5xl"
        variant="h1"
        component="h1"
      >
        {title}
      </Typography>

      <div>
        <p className="p-6">{description}</p>
      </div>
    </Grid>
  );
}
