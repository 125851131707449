import { Button, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Headline from "../layouts/Headline";

export default function SyntexToString() {
  const [state, setState] = useState("");

  return (
    <Page
      isActive={true}
      title={"Syntex - String"}
      description="Introducing our cutting-edge Syntax to String and String to Syntax Conversion Web Application – the ultimate tool for developers! "
      path="/syntex"
    >
      <LeftComponent>
        <Headline text="Write your code and syntex" />
        <div className="!m-2 p-4">
          <TextField
            id="filled-basic"
            variant="outlined"
            label="Syntex | Parse"
            className="w-full"
            placeholder="Write your syntex and paragraph..."
            multiline
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <Button
            variant="contained"
            className="!my-2"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard
                .writeText(state)
                .then(() => {
                  console.log("Text copied to clipboard");
                })
                .catch((err) => {
                  console.error("Failed to copy text: ", err);
                });
            }}
          >
            <ContentCopyIcon fontSize="medium" />
          </Button>
          <Button
            variant="contained"
            className="!m-2"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard
                .readText()
                .then((clipText) => {
                  setState(clipText);
                })
                .catch((err) => {
                  console.error("Failed to paste text: ", err);
                });
            }}
          >
            <ContentPasteIcon fontSize="medium" />
          </Button>
        </div>
      </LeftComponent>
      <RightContainer>
        <Headline text="Copy your parse code" />
        <div className="!m-2 p-4">
          <TextField
            id="filled-basic"
            variant="outlined"
            label="String | Stringify"
            className="w-full"
            placeholder="Write your syntex and paragraph..."
            multiline
            value={JSON.stringify(state)}
            onChange={(e) => {
              try {
                setState(JSON.parse(e.target.value));
              } catch (error) {
                alert(
                  "Warning: Double quotes non removable please write anything into itself"
                );
              }
            }}
          />
          <Button
            variant="contained"
            className="!my-2"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard
                .writeText(JSON.stringify(state))
                .then(() => {
                  console.log("Text copied to clipboard");
                })
                .catch((err) => {
                  console.error("Failed to copy text: ", err);
                });
            }}
          >
            <ContentCopyIcon fontSize="medium" />
          </Button>
          <Button
            variant="contained"
            className="!m-2"
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard
                .readText()
                .then((clipText) => {
                  setState(JSON.parse(clipText));
                })
                .catch((err) => {
                  console.error("Failed to paste text: ", err);
                });
            }}
          >
            <ContentPasteIcon fontSize="medium" />
          </Button>
        </div>
      </RightContainer>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h2 className="fs-10 font-weight-bold how-to__heading-large">
              Syntax Conversion Web Application
            </h2>
            <p className="py-2">
              Introducing our cutting-edge Syntax to String and String to Syntax
              Conversion Web Application – the ultimate tool for developers!
              Whether you're a coding enthusiast or a pro, this application is a
              game-changer. Easily convert code syntax into text and vice versa
              with just a few clicks. Say goodbye to manual conversions and
              hello to efficiency! Join us in this tutorial as we unveil the
              full potential of this web app and show you how to supercharge
              your coding projects. Get ready to code smarter, not harder.
              Subscribe for more tech insights and stay at the forefront of web
              development! 💻🌐 #WebDevelopment #CodingTools #SyntaxConversion
              #WebApp
            </p>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}
