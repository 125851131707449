import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import voices from "../../utils/lang.json";

export default function TextToAudioDownloader() {
  const [state, setState] = useState("en");
  const [text, setText] = useState("");

  return (
    <Page
      isActive={true}
      title={"Text to Audio Downloader"}
      description="Our Text to Audio Downloader is a powerful and user-friendly tool that
          effortlessly transforms written content into high-quality audio files."
      path="/text_to_audio"
    >
      <LeftComponent>
        <FormControl className="!m-3">
          <InputLabel id="demo-simple-select-label">Voice</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state}
            label="Voice"
            onChange={(e) => setState(e.target.value)}
          >
            {voices.map((voiceEle, i) => (
              <MenuItem key={i} value={voiceEle.lang}>
                {voiceEle.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className="!m-3 overflow-auto"
          onChange={(e) => setText(e.target.value)}
          placeholder="Write your text here..."
          rows={10}
          variant="outlined"
          value={text}
          multiline
        ></TextField>
      </LeftComponent>
      <RightContainer>
        <div className="!m-3">
          <Button
            variant="contained"
            className="w-full"
            onClick={async (e) => {
              e.preventDefault();
              let res = await axios.post(
                process.env.REACT_APP_BACKEND_URL + "v1/tts/langspecific",
                {
                  text: text,
                  lang: state,
                }
              );
              if (res.data.status) {
                console.log(res.data.data);
                const bufferData = new Uint8Array(res.data.data.data);
                const blob = new Blob([bufferData], { type: "audio/wav" });
                const objectURL = window.URL.createObjectURL(blob);
                var audio = document.getElementById("audio");
                // audio.play();
                let download = document.getElementById("download");
                audio.src = objectURL;
                download.href = objectURL;
              } else {
                console.log("Error Recevied!");
              }
            }}
          >
            Convert
          </Button>

          <Stack
            display="flex"
            margin={2}
            alignItems="center"
            textAlign="center"
          >
            <audio
              download="aa.mp3"
              id="audio"
              src=""
              controls
              controlsList="nodownload"
            ></audio>
            <Link
              m={2}
              href="/"
              id="download"
              download="tts.mp3"
              variant="contained"
            >
              <Button variant="contained">Download MP3</Button>
            </Link>
          </Stack>
        </div>
      </RightContainer>

      <DescriptionContainer>
        <Typography variant="h2" className="font-bold text-2xl">
          Welcome to our Text to Audio Converter!
        </Typography>
        <p className="py-2">
          Our Text to Audio Converter is a powerful and user-friendly tool that
          effortlessly transforms written content into high-quality audio files.
          Whether you want to bring life to your blog posts, e-books, articles,
          or any other text-based content, our converter is here to help you
          create engaging and accessible audio versions.
        </p>
        <h3>Key Features:</h3>
        <ol style={{ listStyle: "auto" }}>
          <li className="py-2">
            Easy-to-Use Interface: Our converter boasts an intuitive and
            user-friendly interface, making it accessible to users of all levels
            of tech-savviness. You can convert your text to audio with just a
            few clicks, no complicated setups or installations required.
          </li>
          <li className="py-2">
            Multilingual Support: We understand the diverse nature of content on
            the internet, which is why our Text to Audio Converter supports
            multiple languages. It allows you to create audio files in various
            languages, ensuring your content reaches a global audience.
          </li>
          <li className="py-2">
            Natural Sounding Voices: Say goodbye to robotic and monotonous
            voiceovers! Our converter employs advanced text-to-speech (TTS)
            technology that produces natural-sounding voices, making the
            listening experience enjoyable and engaging for your audience.
          </li>
          <li className="py-2">
            Customizable Voice Options: Tailor the audio to match your brand's
            personality by choosing from a selection of voices, pitches, and
            tones. You can add a human touch to your content, reflecting your
            unique style and connecting with your audience on a personal level.
          </li>
          <li className="py-2">
            Adjustable Speed and Pronunciation: You have full control over the
            speed of narration and pronunciation of certain words or phrases.
            This feature ensures that the audio fits the context and tone of
            your content perfectly.
          </li>
          <li className="py-2">
            Batch Processing: Save time and effort by converting multiple texts
            into audio simultaneously. Our batch processing feature lets you
            create audio files in bulk, ideal for large projects or content
            libraries.
          </li>
          <li className="py-2">
            Cloud-Based Solution: There's no need to worry about storage
            limitations or processing power. Our Text to Audio Converter
            operates on the cloud, providing seamless access from any device
            with an internet connection.
          </li>
          <li className="py-2">
            Offline Downloads: Don't let connectivity issues hinder your
            productivity. You can download your audio files and listen to them
            offline at your convenience.
          </li>
          <li className="py-2">
            Privacy and Security: We take data privacy and security seriously.
            Rest assured that your texts and converted audio files are protected
            and treated with utmost confidentiality.
          </li>
        </ol>
        <p className="py-2">
          Enhance your content's accessibility, reach a broader audience, and
          engage your users with our Text to Audio Converter. Start converting
          your text into captivating audio content now!
        </p>
      </DescriptionContainer>
    </Page>
  );
}
