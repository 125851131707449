import Tools from "./components/Tools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HtmlPreview from "./components/tools/HtmlPreview";
import TextToAudioDownloader from "./components/tools/TextToAudioDownloader";
import ImageUploader from "./components/tools/ImageUploader";
import QRGenerator from "./components/tools/QRGenerator";
import Resume from "./components/tools/Resume";
import DataToImage from "./components/tools/DataToImage";
import ImageToData from "./components/tools/ImageToData";
import WebCam from "./components/tools/WebCam";
import TextToAudioPlay from "./components/tools/TextToAudioPlay";
import Age from "./components/tools/Age";
import Color from "./components/tools/Color";
import UrlShortner from "./components/tools/Url";
import SyntexToString from "./components/tools/SyntexToString";
import AnimatePlayer from "./components/tools/AnimatePlayer";
import { Suspense } from "react";
import Speech from "./components/tools/Speech";
import Canvas from "./components/tools/Canvas";
import CanvasPlayer from "./components/tools/CanvasPlayer";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path="/" element={<Tools />} />
          <Route path="/html_preview" element={<HtmlPreview />} />
          <Route path="/text_to_audio" element={<TextToAudioDownloader />} />
          <Route path="/image_uploader" element={<ImageUploader />} />
          <Route path="/qr_generator" element={<QRGenerator />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/decode" element={<DataToImage />} />
          <Route path="/encode" element={<ImageToData />} />
          <Route path="/webcam" element={<WebCam />} />
          <Route path="/ttsplay" element={<TextToAudioPlay />} />
          <Route path="/speech" element={<Speech />} />
          <Route path="/age" element={<Age />} />
          <Route path="/color" element={<Color />} />
          <Route path="/url" element={<UrlShortner />} />
          <Route path="/syntex" element={<SyntexToString />} />
          <Route path="/animate" element={<AnimatePlayer />} />
          <Route path="/canvas" element={<Canvas />} />
          <Route path="/canvas-card" element={<CanvasPlayer />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
