import React, { useEffect, useRef, useState } from "react";
import "../../canvas.css";
import DescriptionContainer from "../layouts/DescriptionContainer";
import Page from "../Page";

// Constants

// State

let brushSize = 10;
let brushColor = "#ef4444";
let canvasBackgroundColor = "#ffffff";
let renderingContext = undefined;
let isMouseDown = false;
let drawings = [];

function Canvas() {
  const [state, setState] = useState({
    brushColor: "#ef4444",
    brushSize: 10,
    bgColorPicker: "#ffffff",
    eraser: false,
  });
  const canvasElement = useRef(null);
  useEffect(() => {
    // Dom Elements
    const saveCanvasElement = document.getElementById("saveCanvas");
    const loadCanvasElement = document.getElementById("loadCanvas");
    // Event listeners
    saveCanvasElement?.addEventListener("click", onSaveCanvas);
    loadCanvasElement?.addEventListener("click", onLoadCanvas);

    // Functions

    function initCanvas(canvasElement) {
      canvasElement.current.width = window.innerWidth - 80;
      canvasElement.current.height = window.innerHeight - 72;
      renderingContext = canvasElement.current.getContext("2d");
      renderingContext.fillStyle = state.bgColorPicker;
      renderingContext.fillRect(
        0,
        0,
        canvasElement.current?.width,
        canvasElement.current?.height
      );
      drawings.length && restoreDrawing();
    }
    // On load
    if (canvasElement) {
      initCanvas(canvasElement);
    }
  }, []);

  function onClearCanvas(e, canvasElement) {
    e.preventDefault();
    drawings = [];
    renderingContext.fillStyle = state.bgColorPicker;
    console.log("clear2", canvasElement);
    renderingContext.fillRect(
      0,
      0,
      canvasElement.current?.width,
      canvasElement.current?.height
    );
    drawings.length && restoreDrawing();
  }

  function onSaveCanvas() {
    localStorage.setItem("canvas", JSON.stringify(drawings));
  }

  function onLoadCanvas() {
    const shape = JSON.parse(localStorage.getItem("canvas")) || [];
    drawings = shape;
    drawings && restoreDrawing();
  }

  function onDeleteCanvas() {
    localStorage.removeItem("canvas");
  }

  function onDownloadCanvas(e, canvasElement) {
    e.preventDefault();
    const downloadCanvasElement = document.getElementById("downloadCanvas");
    downloadCanvasElement.href = canvasElement.current.toDataURL(
      "image/jpeg",
      1
    );
    downloadCanvasElement.download = "paint";
    downloadCanvasElement.click();
  }
  // Utility functions

  function saveLine(coordinates) {
    let point = {
      x: coordinates.x,
      y: coordinates.y,
      brushColor,
      brushSize,
    };
    drawings.push(point);
  }

  function onMouseUp() {
    isMouseDown = false;
  }

  function onMouseMove(e) {
    if (isMouseDown) {
      const mousePosition = getMousePosition(e.nativeEvent);
      drawLine(mousePosition);
      saveLine(mousePosition);
    }
  }

  function drawLine(point) {
    renderingContext.lineTo(point.x, point.y);
    renderingContext.stroke();
  }

  function restoreDrawing() {
    for (let i = 1; i < drawings.length; i++) {
      renderingContext.strokeStyle = drawings[i].brushColor;
      renderingContext.lineWidth = drawings[i].brushSize;
      renderingContext.lineCap = "round";
      renderingContext.beginPath();
      renderingContext.moveTo(drawings[i - 1].x, drawings[i - 1].y);
      drawLine(drawings[i]);
    }
  }

  function onCanvasBgColorChange(e, canvasElement) {
    canvasBackgroundColor = e.target.value;
    renderingContext.fillStyle = state.bgColorPicker;
    renderingContext.fillRect(
      0,
      0,
      canvasElement.nativeEvent?.width,
      canvasElement.nativeEvent?.height
    );
    drawings.length && restoreDrawing();
  }

  function getMousePosition(e) {
    return {
      x: e.offsetX,
      y: e.offsetY,
    };
  }

  function onMouseDown(e, state) {
    isMouseDown = true;
    const mousePosition = getMousePosition(e.nativeEvent);
    setRenderingContextAttributes(mousePosition, state, state.eraser);
  }

  function setRenderingContextAttributes(mousePosition, state, eraser) {
    renderingContext.strokeStyle = eraser
      ? state.bgColorPicker
      : state.brushColor;
    renderingContext.lineWidth = eraser
      ? state.brushSize + 10
      : state.brushSize;
    renderingContext.lineCap = "round";
    renderingContext.beginPath();
    renderingContext.moveTo(mousePosition.x, mousePosition.y);
  }

  function onBrushClicked() {
    setState({ ...state, eraser: !state.eraser });
  }

  function setBrushAttributes(size, color) {
    brushSize = size;
    brushColor = color;
  }

  return (
    <Page
      isActive={false}
      title={"Canvas Paint"}
      description="Introducing our cutting-edge Paint Web Application – the ultimate tool for developers! "
      path="/canvas"
    >
      <div className="border border-black">
        <head>
          <link
            rel="shortcut icon"
            href="/assets/favicon.ico"
            type="image/x-icon"
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="true"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
            rel="stylesheet"
          />
          <link rel="stylesheet" href="/styles/styles.css" />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
            integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
        </head>
        <header className="header flex items-center justify-center">
          {/* <!-- Brush --> */}
          <div
            id="brush"
            title="Brush"
            className={
              state.eraser
                ? "tool flex justify-center items-center rounded w-12 h-12 text-xl cursor-pointer"
                : "tool flex justify-center items-center rounded w-12 h-12 text-xl cursor-pointer tool--active"
            }
            onClick={onBrushClicked}
          >
            <i className="fas fa-pencil"></i>
          </div>
          {/* <!-- Pen color picker --> */}
          <div className="ml-3">
            <input
              type="color"
              value={state.brushColor}
              title="Brush Color"
              id="brushColor"
              onChange={(e) => {
                setState({ ...state, brushColor: e.target.value });
                setBrushAttributes(10, e.target.value);
              }}
              className="cursor-pointer"
            />
          </div>
          {/* <!-- Pen size range slider --> */}
          <div className="ml-3">
            <input
              type="range"
              min="1"
              max="50"
              value={state.brushSize}
              title="Brush Size"
              id="brushSize"
              onChange={(e) => {
                setState({ ...state, brushSize: e.target.value });
                setBrushAttributes(state.brushSize, state.brushColor);
              }}
              // className="slider"
            />
          </div>
          {/* <!-- Whiteboard background --> */}
          {/* <div
          title="Whiteboard colour"
          className="tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3"
        >
          <i className="fas fa-fill-drip"></i>
        </div> */}
          {/* <!-- Whiteboard background color picker --> */}
          <div className="ml-3">
            <input
              type="color"
              value={state.bgColorPicker}
              title="Canvas Color"
              // id="bgColorPicker"
              className="cursor-pointer"
              onChange={(e) => {
                setState({ ...state, bgColorPicker: e.target.value });
                onCanvasBgColorChange(e, canvasElement);
                onClearCanvas(e, canvasElement);
              }}
            />
          </div>
          {/* <!-- Eraser --> */}
          <div
            title="Eraser"
            id="eraser"
            className={
              state.eraser
                ? "tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3 cursor-pointer tool--active"
                : "tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3 cursor-pointer"
            }
            onClick={() => setState({ ...state, eraser: !state.eraser })}
          >
            <i className="fas fa-eraser"></i>
          </div>
          {/* <!-- Clear all --> */}
          <div
            id="clearCanvas"
            title="Clear all"
            className="tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3 cursor-pointer"
            onClick={(e) => onClearCanvas(e, canvasElement)}
          >
            <i className="fas fa-undo-alt"></i>
          </div>
          <div
            id="deleteSavedCanvas"
            title="Delete saved paint"
            className="tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3 cursor-pointer"
            // onClick={onDeleteCanvas}
            onClick={(e) => onDeleteCanvas(e, canvasElement)}
          >
            <i className="fas fa-trash-alt"></i>
          </div>
          {/* <!-- Save change to local storage --> */}
          <div
            id="saveCanvas"
            title="Save to local storage"
            class="tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3 cursor-pointer"
          >
            <i class="fas fa-download"></i>
          </div>
          {/* <!-- Fetch changes from local storage --> */}
          <div
            id="loadCanvas"
            title="Fetch last saved paint"
            class="tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3 cursor-pointer"
          >
            <i class="fas fa-upload"></i>
          </div>
          {/* <!-- Download paint as image --> */}

          <div
            title="Download paint as image"
            className="tool flex justify-center items-center rounded w-12 h-12 text-xl ml-3 cursor-pointer"
            onClick={(e) => onDownloadCanvas(e, canvasElement)}
          >
            <i className="far fa-save"></i>
          </div>
          <a className="invisible" href="/" id="downloadCanvas">
            Download
          </a>
        </header>
        <canvas
          className="canvas"
          onMouseDown={(e) => onMouseDown(e, state)}
          onMouseUp={(e) => onMouseUp(e, state)}
          onMouseMove={(e) => onMouseMove(e, state)}
          ref={canvasElement}
          id="canvas"
        ></canvas>
      </div>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h1 className="fs-10 font-weight-bold how-to__heading-large text-2xl font-bold">
              Canvas Paint
            </h1>
            <p className="py-2">
              The ultimate digital canvas paint tool designed to ignite your
              creativity and bring your artistic visions to life. Whether you're
              an aspiring artist, a professional illustrator, or simply someone
              who loves to create, our tool provides you with the perfect
              platform to express yourself.
            </p>

            <p className="py-4">
              <strong>Key Features:</strong>
            </p>
            <p className="py-2">
              🎨 <strong>Limitless Possibilities</strong>: Dive into a world of
              endless creative opportunities. Paint, draw, and design with a
              wide range of digital brushes and tools at your fingertips.
            </p>
            <p className="py-2">
              🌟 <strong>Realistic Brushwork</strong>: Experience the natural
              feel of painting with our realistic brush strokes and textures.
              It's as if you're working on a physical canvas.
            </p>
            <p className="py-2">
              🎭 <strong>Layers and Blending</strong>: Create depth and
              dimension in your artwork with layers and blending modes that let
              you achieve professional-grade results.
            </p>
            <p className="py-2">
              🖼️ <strong>Customizable Canvas</strong>: Choose from various
              canvas sizes and dimensions, ensuring that your art fits your
              vision perfectly.
            </p>
            <p className="py-2">
              🎉 <strong>Undo/Redo</strong>: Don't be afraid to experiment. Our
              unlimited undo/redo feature lets you explore without hesitation.
            </p>
            <p className="py-2">
              💾 <strong>Save and Share</strong>: Save your masterpieces and
              share them with the world or keep them for your private
              collection.
            </p>
            <p className="py-4">
              <strong>Why Choose Canvas Paint?</strong>
            </p>
            <p className="py-2">
              🎁 <strong>Innovative Technology</strong>: Our advanced technology
              ensures a smooth and intuitive painting experience that artists of
              all levels will appreciate.
            </p>
            <p className="py-2">
              🌐 <strong>Web-Based Convenience</strong>: Access [Your Tool Name]
              from any device with an internet connection, giving you the
              freedom to create from anywhere.
            </p>
            <p className="py-2">
              🎨 <strong>Inspiration Awaits</strong>: Be inspired by the
              ever-growing community of artists and their diverse creations.
            </p>
            <p className="py-2">
              🔒 <strong>Safe and Secure</strong>: We prioritize your privacy
              and data security, so you can paint with peace of mind.
            </p>
            <p className="py-2">
              Whether you're a digital art enthusiast, an illustrator, or
              someone who simply enjoys the therapeutic nature of painting,
              Canvas Paint offers a welcoming space for your artistic journey.
            </p>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}

export default Canvas;

function throttle(Fn, delay) {
  let flag = true;
  return function (...args) {
    if (flag) {
      Fn(...args);
      flag = false;
      setTimeout(() => {
        flag = true;
      }, delay);
    }
  };
}
