import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import QRCode from "react-qr-code";
import Headline from "../layouts/Headline";

export default function QRGenerator() {
  const [state, setState] = useState("");

  return (
    <Page
      isActive={true}
      title={"QR Generator"}
      description="Our QR Generator is a powerful and user-friendly tool that
          effortlessly transforms written content into QR."
      path="/qr_generator"
    >
      <LeftComponent>
        <Headline text="Write your link and message" />
        <TextField
          className="!m-3 overflow-auto"
          onChange={(e) => setState(e.target.value)}
          placeholder="Write your text here..."
          rows={10}
          variant="outlined"
          value={state}
          multiline
        ></TextField>
      </LeftComponent>
      <RightContainer>
        <Headline text="Get your QR and share" />
        <div className="pt-3 flex flex-col gap-y-2 items-center ">
          <QRCode value={state} />
          <Stack>
            <Button
              variant="contained"
              onClick={(e) => {
                //get svg element.
                var svg = document.getElementsByTagName("svg")[0];
                //get svg source.
                var serializer = new XMLSerializer();
                var source = serializer.serializeToString(svg);

                //add name spaces.
                if (
                  !source.match(
                    /^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/
                  )
                ) {
                  source = source.replace(
                    /^<svg/,
                    '<svg xmlns="http://www.w3.org/2000/svg"'
                  );
                }
                if (
                  !source.match(
                    /^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/
                  )
                ) {
                  source = source.replace(
                    /^<svg/,
                    '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
                  );
                }

                //add xml declaration
                source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

                //convert svg source to URI data scheme.
                var url =
                  "data:image/svg+xml;charset=utf-8," +
                  encodeURIComponent(source);

                //set url value to a element's href attribute.
                document.getElementById("download").href = url;
                document.getElementById("download").download = "QR.svg";
                //you can download svg file by right click menu.
                document.getElementById("download").click();
              }}
            >
              Download QR
            </Button>
            <Link
              m={2}
              href=""
              id="download"
              // download="qr.png"
              download
              variant="contained"
            />
            {/* <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  const data = {
                    title: "QR CODE",
                    text: "Sample url",
                    url: "https://ioberg.com",
                  };
                  if (navigator.canShare && navigator.canShare(data)) {
                    navigator.share(data);
                  } else {
                    alert("Share not support!");
                  }
                }}
              >
                Share QR
              </Button> */}
          </Stack>
        </div>
      </RightContainer>

      <DescriptionContainer>
        <Typography variant="h2" className="text-2xl font-bold">
          Welcome to our Text to Audio Converter!
        </Typography>
        <p className="py-2">
          Using a QR code generator is a simple process that involves inputting
          data and generating a QR code that represents that data. Here's how
          you can use a QR code generator:
        </p>
        <h3>Key Features:</h3>
        <ol style={{ listStyle: "auto" }}>
          <li className="py-2">
            Access the QR Code Generator: Open your web browser and navigate to
            the website or online tool that offers a QR code generator. This
            tool should have a user interface where you can input the data you
            want to encode.
          </li>
          <li className="py-2">
            Input Your Data: On the QR code generator's user interface, locate
            the input field provided. This field is where you will enter the
            data you want to encode into the QR code. The type of data you can
            input might include a URL, text, contact information, or any other
            content that QR codes can represent.
          </li>
          <li className="py-2">
            Choose Generation Options (if available): Some QR code generators
            offer additional customization options. These might include the
            ability to change the color of the QR code, add a logo or image
            overlay, adjust error correction levels, or select the QR code size.
            If you want to customize the appearance of the QR code, explore
            these options.
          </li>
          <li className="py-2">
            Generate the QR Code: After inputting your data and making any
            desired customization selections, click the "Generate" or "Create"
            button. This action will trigger the QR code generator to process
            your input and create a QR code imageGenerate the QR Code: After
            inputting your data and making any desired customization selections,
            click the "Generate" or "Create" button. This action will trigger
            the QR code generator to process your input and create a QR code
            image
          </li>
        </ol>
      </DescriptionContainer>
    </Page>
  );
}
