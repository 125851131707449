import { Paper, Typography } from "@mui/material";
import React from "react";

function Headline({ text }) {
  return (
    <Paper
      elevation={0}
      className="flex mobile:flex-col laptop:flex-row mt-6 mb-3 !text-dark"
    >
      <Typography className="!text-2xl flex-1 !m-3" variant="h2">
        {text}
      </Typography>
    </Paper>
  );
}

export default Headline;
