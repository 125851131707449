import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BasicAppBar from "../components/layouts/Header";
import ToolTitleDescription from "./layouts/ToolTitleDescription";
import cards from "../components/data/cards.json";
import ToolCard from "./ToolCard";
import Particle from "./Particle";
import Footer from "./layouts/Footer";

export default function Page({ isActive, title, description, path, children }) {
  useEffect(() => {
    document.getElementsByTagName("title")[0].text =
      title + ": All in One Place";
    document.querySelector("[name=description]").content = description;
    document.querySelector("[rel=canonical]").href = path;
    document.querySelector("[name=robots]").content = "index, follow";
    document.querySelector("meta[name='og:type']").content = "tool";
    document.querySelector("meta[name='og:title']").content = title;
    document.querySelector("meta[name='og:description']").content = description;
    document.querySelector("meta[name='og:image']").content =
      "https://iobergfile.s3.ap-south-1.amazonaws.com/1691493319_Logo.png";
    document.querySelector("meta[name='og:url']").content = path;
    document.querySelector("meta[name='og:site_name']").content = "IOBERG";
    document.querySelector("meta[name='twitter:title']").content = title;
    document.querySelector("meta[name='twitter:description']").content =
      description;
    document.querySelector("meta[name='twitter:image']").content =
      "https://iobergfile.s3.ap-south-1.amazonaws.com/1691493319_Logo.png";
    document.querySelector("meta[name='twitter:site']").content = "@stirthraj";
    document.querySelector("meta[name='twitter:creator']").content =
      "@stirthraj";
  }, []);

  return (
    <>
      <Grid className="!bg-slate-100" container spacing={2}>
        <Grid item xs={12}>
          <header>
            <BasicAppBar />
          </header>
        </Grid>
        {/* <Particle /> */}
        <Container maxWidth="lg" className="mt-8">
          <ToolTitleDescription
            title={title}
            description={description}
            isActive={isActive}
          />
          <Grid item xs={12}>
            <div className="flex flex-wrap justify-center m-1 p-6">
              {children}
            </div>
          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Grid container spacing={2} className="p-3">
            <ToolCard cards={cards} xs={12} sm={6} md={3} />
          </Grid>
        </Container>

        {/* <Grid item xs={12}>
          <Box
            component="footer"
            sx={{
              py: 3,
              px: 2,
              mt: "auto",
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[800],
            }}
          >
            <Container maxWidth="lg">
              <Typography variant="body1">
                Ioberg presents innovative tools.
              </Typography>

              <Typography variant="body2" color="text.secondary">
                {"Copyright © "}
                Ioberg
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Container>
          </Box>
        </Grid> */}
        <Footer />
      </Grid>
    </>
  );
}
