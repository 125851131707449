import { Button, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import Headline from "../layouts/Headline";

export default function ImageToData() {
  const [state, setState] = useState("");

  return (
    <Page
      isActive={true}
      title={"Image to Base64 Encode"}
      description="Our Image to Base64 Encode tool empowers you to effortlessly convert images into Base64 encoded strings. Base64 encoding is a technique that transforms binary data, such as images, into a text-based format, making it easily transferable and embeddable in various applications and websites."
      path="/encode"
    >
      <LeftComponent>
        <Headline text="Upload any your image" />
        <TextField
          className="!m-2 h-[40vh] overflow-auto  p-4"
          variant="outlined"
          onChange={async (e) => {
            try {
              let dataUrl = await new Promise((resolve) => {
                let reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.readAsDataURL(e.target.files[0]);
              });
              setState(dataUrl);
            } catch (error) {
              console.error("Cannot upload image.");
              console.error(error);
            }
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={async (e) => {
            //prevent the browser from opening the image
            e.preventDefault();
            e.stopPropagation();

            let dataUrl = await new Promise((resolve) => {
              let reader = new FileReader();
              reader.onload = () => resolve(reader.result);
              reader.readAsDataURL(e.dataTransfer.files[0]);
            });
            setState(dataUrl);
          }}
          rows={6}
          type="file"
          placeholder="Write your image data here..."
        ></TextField>
      </LeftComponent>
      <RightContainer>
        <Headline text="Encoded base64 data" />
        <TextField
          className="!m-2 h-[40vh] overflow-auto bg-slate-200 p-4"
          value={state}
          variant="outlined"
          placeholder="Write your image data here..."
          multiline
        ></TextField>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard
              .writeText(state)
              .then(() => {
                console.log("Text copied to clipboard");
              })
              .catch((err) => {
                console.error("Failed to copy text: ", err);
              });
          }}
          variant="contained"
        >
          COPY Data
        </Button>
      </RightContainer>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h2 className="fs-10 font-weight-bold how-to__heading-large">
              Image to Base64 Encode tool
            </h2>

            <p className="py-2">
              Our tool empowers you to effortlessly convert images into Base64
              encoded strings.
            </p>
            <p className="py-2">
              Base64 encoding is a technique that transforms binary data, such
              as images, into a text-based format, making it easily transferable
              and embeddable in various applications and websites. By using our
              tool, you can upload your desired image and instantly generate its
              corresponding Base64 code.
            </p>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}
