import { TextField, Button, Box, Stack, Link } from "@mui/material";
import React from "react";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import axios from "axios";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Headline from "../layouts/Headline";

export default function UrlShortner() {
  const [url, setUrl] = useState({
    origUrl: " ",
    shortUrl: " ",
    clicks: " ",
  });

  async function getShortLink() {
    let ress = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "v1/url/short",
      {
        origUrl: url.origUrl,
      }
    );
    const { clicks, shortUrl } = ress.data;
    setUrl({ ...url, shortUrl: shortUrl, clicks });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    getShortLink();
  };

  return (
    <Page
      isActive={true}
      title={"URL Shortner"}
      description="A URL shortener tool is a web-based or software application designed to simplify and condense long website URLs into shorter, more manageable links."
      path="/url"
    >
      <LeftComponent>
        <Headline text="Make your short url" />
        <Box component="form" onSubmit={handleSubmit} className="!m-2 p-4">
          <TextField
            className="w-full"
            variant="outlined"
            placeholder="Write or paste your link"
            onChange={(e) => setUrl({ ...url, origUrl: e.target.value })}
          />
          <Button className="!my-2" variant="contained" type="submit">
            Get Short Link
          </Button>
        </Box>
      </LeftComponent>
      <RightContainer>
        <Headline text="Get short link" />
        <div className="!m-2 w-full p-4">
          <Stack
            display="flex"
            margin={2}
            alignItems="center"
            textAlign="center"
          >
            <Link
              m={2}
              href={url.shortUrl}
              variant="contained"
              className="border-2 border-solid flex justify-between p-2 items-center rounded-md min-w-[300px]"
            >
              <div> {url.shortUrl}</div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard
                    .writeText(url.shortUrl)
                    .then(() => {
                      console.log("Text copied to clipboard");
                    })
                    .catch((err) => {
                      console.error("Failed to copy text: ", err);
                    });
                }}
              >
                <ContentCopyIcon fontSize="medium" />
              </Button>
            </Link>
          </Stack>
          <Stack display="flex" margin={2} spacing={2}>
            <Button className="!justify-start" size="small" variant="contained">
              Original Url: {url.origUrl}
            </Button>
            <Button className="!justify-start" size="small" variant="contained">
              Short Url: {url.shortUrl}
            </Button>
            <Button className="!justify-start" size="small" variant="contained">
              Clicks: {url.clicks}
            </Button>
          </Stack>
        </div>
      </RightContainer>

      <DescriptionContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 how-to">
            <h2 className="fs-10 font-weight-bold how-to__heading-large">
              URL Shortner
            </h2>
            <p className="py-2">
              A URL shortener tool is a web-based or software application
              designed to simplify and condense long website URLs into shorter,
              more manageable links. These tools are commonly used for a variety
              of purposes, including sharing links on social media, improving
              the appearance of URLs in printed materials, and tracking
              click-through rates. Here's a brief description of how a URL
              shortener tool typically works:
            </p>
            <ol>
              <li className="py-2">
                <p className="py-2">
                  <strong>Input URL</strong>: Users provide the tool with a long
                  and unwieldy URL that they want to shorten. This could be a
                  link to a webpage, a document, an image, or any other online
                  resource.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Shortening Process</strong>: The URL shortener tool
                  processes the input URL and generates a new, shorter, and
                  unique URL. This shortened URL is typically much shorter than
                  the original one, making it easier to share and remember.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Generated Short URL</strong>: The tool displays the
                  newly created short URL, which users can then copy and share
                  with others.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Redirection</strong>: When someone clicks on the short
                  URL, they are automatically redirected to the original, longer
                  URL. This redirection happens in a fraction of a second, and
                  users are often unaware of it.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Analytics (Optional)</strong>: Some URL shortener
                  tools offer additional features, such as analytics, which
                  allow users to track the number of clicks, geographic
                  locations of users, and other data related to the usage of the
                  shortened URL.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Customization (Optional)</strong>: Many URL shorteners
                  allow users to customize the generated short URL with a custom
                  alias or keyword, making it more branded and meaningful.
                </p>
              </li>
              <li className="py-2">
                <p className="py-2">
                  <strong>Expiration (Optional)</strong>: Some URL shorteners
                  offer the ability to set an expiration date for the shortened
                  link, after which it will no longer work.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </DescriptionContainer>
    </Page>
  );
}
