import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Button, Link } from "@mui/material";
// import { Link } from "react-router-dom";

export default function BasicAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="inherit" position="static">
        <Toolbar
          className="justify-between flex-col laptop:flex-row gap-y-4 py-2 !bg-slate-900"
          color="secondary"
        >
          <a className="w-auto" href="/">
            <img
              src="/logo.png"
              alt=" IOBERG TOOLS"
              height={40}
              width="auto"
              className="h-[40px]"
            />
          </a>
          <a className="w-auto" href="https://my.ioberg.com/">
            <Button
              variant="outlined"
              className="w-[200px] !text-white"
              color="primary"
            >
              MyIoberg Handle
            </Button>
          </a>

          {/* <Button className="w-auto" color="inherit">
            Login
          </Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
