import { Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import RightContainer from "../layouts/RightContainer";
import DescriptionContainer from "../layouts/DescriptionContainer";
import Page from "../Page";
import LeftComponent from "../layouts/LeftComponent";
import jsPDF from "jspdf";

export default function Resume() {
  // Default export is a4 paper, portrait, using millimeters for units

  // const tableObj = {
  //   name: "Tirth Raj Kumar",
  //   father_name: "Abhinay Kumar Singh",
  //   mother_name: "Anita Devi",
  //   mobile: "7978821389",
  //   email: "tirth@google.com",
  //   gender: "female",
  //   dob: "15/01/1998",
  //   religion: "hindu",
  //   language: ["english,hindi,bhojpuri"],
  //   qualification: [
  //     {
  //       name: "10th",
  //       college_name: "Islamia Urdu Academy, Mirganj",
  //       year_of_passing: "2013",
  //       percentage: "75.8%",
  //     },
  //     {
  //       name: "12th",
  //       college_name: "Islamia Urdu Academy, Mirganj",
  //       year_of_passing: "2016",
  //       percentage: "75.8%",
  //     },
  //     {
  //       name: "B.tech",
  //       college_name: "Islamia Urdu Academy, Mirganj",
  //       year_of_passing: "2020",
  //       percentage: "75.8%",
  //     },
  //   ],
  //   experience: [
  //     {
  //       company_name: "Eduwheel India",
  //       duration: "Mar/2020-Oct/2020",
  //       designation: "Web Developer",
  //     },
  //     {
  //       company_name: "Eduwheel India",
  //       duration: "Mar/2020-Oct/2020",
  //       designation: "Web Developer",
  //     },
  //   ],
  //   projects: [
  //     {
  //       project_name: "Project 1",
  //       description: "Mar/2020-Oct/2020",
  //       technologies: "Web Developer",
  //     },
  //   ],
  //   address: {
  //     at: "Balahata",
  //     city: "Mirganj",
  //     district: "Gopalganj",
  //     state: "Bihar",
  //   },
  // };
  const tableObj = {
    name: "Rahul Kumar",
    father_name: "-",
    mother_name: "-",
    mobile: "-",
    email: "-",
    gender: "-",
    dob: "-",
    religion: "-",
    language: ["english", "hindi", "bhojpuri"],
    qualification: [
      {
        name: "10th",
        college_name: "Academy, India",
        year_of_passing: "2020",
        percentage: "00.00%",
      },
      {
        name: "12th",
        college_name: "Academy, India",
        year_of_passing: "2016",
        percentage: "00.00%",
      },
      {
        name: "B.tech",
        college_name: "Academy, India",
        year_of_passing: "2020",
        percentage: "00.00%",
      },
    ],
    experience: [
      {
        company_name: "Eduwheel India",
        duration: "Mar/2020-Oct/2020",
        designation: "Web Developer",
      },
      {
        company_name: "Eduwheel India",
        duration: "Mar/2020-Oct/2020",
        designation: "Web Developer",
      },
    ],
    projects: [
      {
        project_name: "Project 1",
        description: "Mar/2020-Oct/2020",
        technologies: "Web Developer",
      },
    ],
    address: {
      at: "-",
      city: "-",
      district: "-",
      state: "-",
    },
  };

  // React.useEffect(() => {
  function printDiv(divName) {
    // var printContents = document.getElementById(divName).innerHTML;

    // var originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;
    window.print();

    // document.body.innerHTML = originalContents;
  }
  // }, []);

  return (
    <Page
      isActive={true}
      title={"Resume Maker"}
      description="Our Image Upload Tool is a powerful and user-friendly tool that effortlessly transforms file to url. Use where you wants to immediately."
    >
      <Container
        // sx={{ p: 3, py: 8 }}
        disableGutters
      >
        <table
          contentEditable={true}
          id="printableArea"
          style={{ width: "100%", border: "1px solid" }}
        >
          <thead className="">
            <tr className="">
              <th className="center" colSpan={4}>
                Bio-Data:
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={2}>Name</td>
              <td colSpan={2}>
                <b>{tableObj.name}</b>
              </td>
            </tr>{" "}
            <tr>
              <td colSpan={2}>Name</td>
              <td colSpan={2}>
                <b>{tableObj.name}</b>
              </td>
            </tr>{" "}
            <tr>
              <td colSpan={2}>Name</td>
              <td colSpan={2}>
                <b>{tableObj.name}</b>
              </td>
            </tr>{" "}
            <tr>
              <td colSpan={2}>Name</td>
              <td colSpan={2}>
                <b>{tableObj.name}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Name</td>
              <td colSpan={2}>
                <b>{tableObj.name}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Father Name</td>
              <td colSpan={2}>{tableObj.father_name}</td>
            </tr>
            <tr>
              <td colSpan={2}>Mother Name</td>
              <td colSpan={2}>{tableObj.mother_name}</td>
            </tr>
            <tr>
              <td colSpan={2}>Mobile</td>
              <td colSpan={2}>{tableObj.mobile}</td>
            </tr>
            <tr>
              <td colSpan={2}>Email</td>
              <td colSpan={2}>{tableObj.email}</td>
            </tr>
            <tr>
              <td colSpan={2}>Gender</td>
              <td colSpan={2}>{tableObj.gender}</td>
            </tr>
            <tr>
              <td colSpan={2}>Date of Birth</td>
              <td colSpan={2}>{tableObj.dob}</td>
            </tr>
            <tr>
              <td colSpan={2}>Religion</td>
              <td colSpan={2}>{tableObj.religion}</td>
            </tr>
            <tr>
              <td colSpan={2}>Language</td>
              <td colSpan={2}>{tableObj.language.join(",")}</td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th className="center" colSpan={4}>
                Qualification:
              </th>
            </tr>
          </thead>
          <tbody>
            {tableObj.qualification.map((resp, i) => (
              <tr key={i}>
                <td>{resp.name}</td>
                <td>{resp.college_name}</td>
                <td>{resp.year_of_passing}</td>
                <td>{resp.percentage}</td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th className="center" colSpan={4}>
                Experience:
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th colSpan={2}>Company Name</th>
              <th>Designation</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {tableObj.experience.map((resp, i) => (
              <tr key={i}>
                <td colSpan={2}>{resp.company_name}</td>
                <td>{resp.designation}</td>
                <td>{resp.duration}</td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th className="center" colSpan={4}>
                Projects:
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th colSpan={2}>Project Name</th>
              <th>Description</th>
              <th>Technologies</th>
            </tr>
          </thead>
          <tbody>
            {tableObj.experience.map((resp, i) => (
              <tr key={i}>
                <td colSpan={2}>{resp.company_name}</td>
                <td>{resp.designation}</td>
                <td>{resp.duration}</td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th className="center" colSpan={4}>
                Address:
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}>At</td>
              <td colSpan={2}>{tableObj.address.at}</td>
            </tr>
            <tr>
              <td colSpan={2}>City</td>
              <td colSpan={2}>{tableObj.address.city}</td>
            </tr>
            <tr>
              <td colSpan={2}>District</td>
              <td colSpan={2}>{tableObj.address.district}</td>
            </tr>
            <tr>
              <td colSpan={2}>State</td>
              <td colSpan={2}>{tableObj.address.state}</td>
            </tr>
          </tbody>

          {/* <tfoot></tfoot> */}
        </table>
        <div className="center noprint  w-100">
          <Button
            variant="contained"
            onClick={() => {
              printDiv("printableArea");
              // var svg = document.getElementsByTagName("svg")[0];
              // var serializer = new XMLSerializer();
              // var source = serializer.serializeToString(svg);
              // //convert svg source to URI data scheme.
              // var url =
              //   "data:image/svg+xml;charset=utf-8," +
              //   encodeURIComponent(source);

              // //set url value to a element's href attribute.
              // document.getElementById("download").href = url;
              // document.getElementById("download").download = "QR.svg";
              // //you can download svg file by right click menu.
              // document.getElementById("download").click();

              // Default export is a4 paper, portrait, using millimeters for units
              // const element = document.getElementById("printableArea");
              // console.log({ element });
              // const pdf = new jsPDF();
              // var options = {
              //   pagesplit: true,
              // };

              // pdf.html("<div>TIrth Raj</div>", options);
              // pdf.save();
            }}
          >
            Print
          </Button>
        </div>
      </Container>
      <DescriptionContainer>Description here...</DescriptionContainer>
    </Page>
  );
}

// const puppeteer = require("puppeteer");
// (async () => {
//   const browser = await puppeteer.launch();
//   const page = await browser.newPage();
//   await page.goto("https://your-domain.com/path-to-invoice", {
//     waitUntil: "networkidle2",
//   });
//   await page.pdf({ path: "invoice-file-path.pdf", format: "a4" });

//   await browser.close();
// })();
