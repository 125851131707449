export const sounds = [
  {
    name: "Rishi",
    lang: "en-IN",
  },
  {
    name: "Albert",
    lang: "en-US",
  },
  {
    name: "Alice",
    lang: "it-IT",
  },
  {
    name: "Alva",
    lang: "sv-SE",
  },
  {
    name: "Amélie",
    lang: "fr-CA",
  },
  {
    name: "Amira",
    lang: "ms-MY",
  },
  {
    name: "Anna",
    lang: "de-DE",
  },
  {
    name: "Bad News",
    lang: "en-US",
  },
  {
    name: "Bahh",
    lang: "en-US",
  },
  {
    name: "Bells",
    lang: "en-US",
  },
  {
    name: "Boing",
    lang: "en-US",
  },
  {
    name: "Bubbles",
    lang: "en-US",
  },
  {
    name: "Carmit",
    lang: "he-IL",
  },
  {
    name: "Cellos",
    lang: "en-US",
  },
  {
    name: "Damayanti",
    lang: "id-ID",
  },
  {
    name: "Daniel",
    lang: "en-GB",
  },
  {
    name: "Daria",
    lang: "bg-BG",
  },
  {
    name: "Wobble",
    lang: "en-US",
  },
  {
    name: "Eddy (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Eddy (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Eddy (English (US))",
    lang: "en-US",
  },
  {
    name: "Eddy (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Eddy (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Eddy (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Eddy (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Eddy (French (France))",
    lang: "fr-FR",
  },
  {
    name: "Eddy (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Eddy (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Ellen",
    lang: "nl-BE",
  },
  {
    name: "Flo (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Flo (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Flo (English (US))",
    lang: "en-US",
  },
  {
    name: "Flo (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Flo (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Flo (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Flo (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Flo (French (France))",
    lang: "fr-FR",
  },
  {
    name: "Flo (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Flo (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Fred",
    lang: "en-US",
  },
  {
    name: "Good News",
    lang: "en-US",
  },
  {
    name: "Grandma (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Grandma (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Grandma (English (US))",
    lang: "en-US",
  },
  {
    name: "Grandma (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Grandma (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Grandma (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Grandma (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Grandma (French (France))",
    lang: "fr-FR",
  },
  {
    name: "Grandma (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Grandma (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Grandpa (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Grandpa (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Grandpa (English (US))",
    lang: "en-US",
  },
  {
    name: "Grandpa (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Grandpa (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Grandpa (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Grandpa (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Grandpa (French (France))",
    lang: "fr-FR",
  },
  {
    name: "Grandpa (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Grandpa (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Jester",
    lang: "en-US",
  },
  {
    name: "Ioana",
    lang: "ro-RO",
  },
  {
    name: "Jacques",
    lang: "fr-FR",
  },
  {
    name: "Joana",
    lang: "pt-PT",
  },
  {
    name: "Junior",
    lang: "en-US",
  },
  {
    name: "Kanya",
    lang: "th-TH",
  },
  {
    name: "Karen",
    lang: "en-AU",
  },
  {
    name: "Kathy",
    lang: "en-US",
  },
  {
    name: "Kyoko",
    lang: "ja-JP",
  },
  {
    name: "Lana",
    lang: "hr-HR",
  },
  {
    name: "Laura",
    lang: "sk-SK",
  },
  {
    name: "Lekha",
    lang: "hi-IN",
  },
  {
    name: "Lesya",
    lang: "uk-UA",
  },
  {
    name: "Linh",
    lang: "vi-VN",
  },
  {
    name: "Luciana",
    lang: "pt-BR",
  },
  {
    name: "Majed",
    lang: "ar-001",
  },
  {
    name: "Tünde",
    lang: "hu-HU",
  },
  {
    name: "Meijia",
    lang: "zh-TW",
  },
  {
    name: "Melina",
    lang: "el-GR",
  },
  {
    name: "Milena",
    lang: "ru-RU",
  },
  {
    name: "Moira",
    lang: "en-IE",
  },
  {
    name: "Mónica",
    lang: "es-ES",
  },
  {
    name: "Montse",
    lang: "ca-ES",
  },
  {
    name: "Nora",
    lang: "nb-NO",
  },
  {
    name: "Organ",
    lang: "en-US",
  },
  {
    name: "Paulina",
    lang: "es-MX",
  },
  {
    name: "Superstar",
    lang: "en-US",
  },
  {
    name: "Ralph",
    lang: "en-US",
  },
  {
    name: "Reed (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Reed (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Reed (English (US))",
    lang: "en-US",
  },
  {
    name: "Reed (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Reed (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Reed (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Reed (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Reed (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Reed (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Rocko (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Rocko (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Rocko (English (US))",
    lang: "en-US",
  },
  {
    name: "Rocko (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Rocko (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Rocko (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Rocko (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Rocko (French (France))",
    lang: "fr-FR",
  },
  {
    name: "Rocko (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Rocko (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Samantha",
    lang: "en-US",
  },
  {
    name: "Sandy (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Sandy (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Sandy (English (US))",
    lang: "en-US",
  },
  {
    name: "Sandy (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Sandy (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Sandy (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Sandy (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Sandy (French (France))",
    lang: "fr-FR",
  },
  {
    name: "Sandy (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Sandy (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Sara",
    lang: "da-DK",
  },
  {
    name: "Satu",
    lang: "fi-FI",
  },
  {
    name: "Shelley (German (Germany))",
    lang: "de-DE",
  },
  {
    name: "Shelley (English (UK))",
    lang: "en-GB",
  },
  {
    name: "Shelley (English (US))",
    lang: "en-US",
  },
  {
    name: "Shelley (Spanish (Spain))",
    lang: "es-ES",
  },
  {
    name: "Shelley (Spanish (Mexico))",
    lang: "es-MX",
  },
  {
    name: "Shelley (Finnish (Finland))",
    lang: "fi-FI",
  },
  {
    name: "Shelley (French (Canada))",
    lang: "fr-CA",
  },
  {
    name: "Shelley (French (France))",
    lang: "fr-FR",
  },
  {
    name: "Shelley (Italian (Italy))",
    lang: "it-IT",
  },
  {
    name: "Shelley (Portuguese (Brazil))",
    lang: "pt-BR",
  },
  {
    name: "Sinji",
    lang: "zh-HK",
  },
  {
    name: "Tessa",
    lang: "en-ZA",
  },
  {
    name: "Thomas",
    lang: "fr-FR",
  },
  {
    name: "Tingting",
    lang: "zh-CN",
  },
  {
    name: "Trinoids",
    lang: "en-US",
  },
  {
    name: "Whisper",
    lang: "en-US",
  },
  {
    name: "Xander",
    lang: "nl-NL",
  },
  {
    name: "Yelda",
    lang: "tr-TR",
  },
  {
    name: "Yuna",
    lang: "ko-KR",
  },
  {
    name: "Zarvox",
    lang: "en-US",
  },
  {
    name: "Zosia",
    lang: "pl-PL",
  },
  {
    name: "Zuzana",
    lang: "cs-CZ",
  },
  {
    name: "Google Deutsch",
    lang: "de-DE",
  },
  {
    name: "Google US English",
    lang: "en-US",
  },
  {
    name: "Google UK English Female",
    lang: "en-GB",
  },
  {
    name: "Google UK English Male",
    lang: "en-GB",
  },
  {
    name: "Google español",
    lang: "es-ES",
  },
  {
    name: "Google español de Estados Unidos",
    lang: "es-US",
  },
  {
    name: "Google français",
    lang: "fr-FR",
  },
  {
    name: "Google हिन्दी",
    lang: "hi-IN",
  },
  {
    name: "Google Bahasa Indonesia",
    lang: "id-ID",
  },
  {
    name: "Google italiano",
    lang: "it-IT",
  },
  {
    name: "Google 日本語",
    lang: "ja-JP",
  },
  {
    name: "Google 한국의",
    lang: "ko-KR",
  },
  {
    name: "Google Nederlands",
    lang: "nl-NL",
  },
  {
    name: "Google polski",
    lang: "pl-PL",
  },
  {
    name: "Google português do Brasil",
    lang: "pt-BR",
  },
  {
    name: "Google русский",
    lang: "ru-RU",
  },
  {
    name: "Google 普通话（中国大陆）",
    lang: "zh-CN",
  },
  {
    name: "Google 粤語（香港）",
    lang: "zh-HK",
  },
  {
    name: "Google 國語（臺灣）",
    lang: "zh-TW",
  },
];
